// src/components/Tournament.js

import React from 'react';
import { Box, Paper, Typography, Grid, Divider } from '@mui/material';
import ResponsiveMatchComponent from './ResposiveMatchComponenet';

const Tournament = () => {
    return (
        <Box sx={{ padding: 2 }}>
            <Grid container spacing={1} columns={32} sx={{ justifyContent: "center", }}>

                {/* Round of 16 */}
                <Grid item xs={8} md={4}>
                    <ResponsiveMatchComponent />
                </Grid>
                <Grid item xs={8} md={4}>
                    <ResponsiveMatchComponent />
                </Grid>
                <Grid item xs={8} md={4}>
                    <ResponsiveMatchComponent />
                </Grid>
                <Grid item xs={8} md={4}>
                    <ResponsiveMatchComponent />
                </Grid>
                <Grid item xs={8} md={4}>
                    <ResponsiveMatchComponent />
                </Grid>
                <Grid item xs={8} md={4}>
                    <ResponsiveMatchComponent />
                </Grid>
                <Grid item xs={8} md={4}>
                    <ResponsiveMatchComponent />
                </Grid>
                <Grid item xs={8} md={4}>
                    <ResponsiveMatchComponent />
                </Grid>


                {/* Quarter-finals */}
                <Grid item xs={8} md={8}>
                    <ResponsiveMatchComponent />
                </Grid>
                <Grid item xs={8} md={8}>
                    <ResponsiveMatchComponent />
                </Grid>
                <Grid item xs={8} md={8}>
                    <ResponsiveMatchComponent />
                </Grid>
                <Grid item xs={8} md={8}>
                    <ResponsiveMatchComponent />
                </Grid>


                {/* Semi-finals */}
                <Grid item xs={16} md={16}>
                    <ResponsiveMatchComponent />
                </Grid>
                <Grid item xs={16} md={16}>
                    <ResponsiveMatchComponent />
                </Grid>

                <Grid item xs={32} > 
                    <Divider />
                </Grid>
                {/* Final */}
                <Grid item xs={32} md={32} sx={{display: "flex", justifyContent: "center", }}>
                    <ResponsiveMatchComponent />
                </Grid>

            </Grid>
        </Box>
    );
};

export default Tournament;
