import React from "react";
import { useAppContext } from "./Base/ContentProvider";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import MainLayout from "./Base/MainLayout";
import LandingPage from "./Pages/LandingPage";
import HomePage from "./Pages/HomePage";
import AdminPage from "./Pages/AdminPage";
import ProfilPage from "./Pages/ProfilePage";
import PlayOffPage from "./Pages/PlayOffPage";

export default function AppRouter() {

    const { user } = useAppContext();


    return (
        <BrowserRouter>
            <MainLayout>
                <Routes>
                    <Route path="/" element={user ? <HomePage /> : <LandingPage />} />
                    <Route path="/home" element={user ? <HomePage /> : <LandingPage />} />
                    <Route path="/playoff" element={user ? <PlayOffPage /> : <LandingPage />} />
                    <Route path="/admin" element={user ? <AdminPage /> : <LandingPage />} />
                    <Route path="/profile" element={user ? <ProfilPage /> : <LandingPage />} />
                </Routes>
            </MainLayout>
        </BrowserRouter>
    )

}