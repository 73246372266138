import { Box, Grid } from "@mui/material";
import React from "react";
import Tournament from "../Componenets/Tournament";

export default function PlayOffPage(){

    return (
        <Box>
            {/* <Grid container columns={32}>

            </Grid> */}
            <Tournament />
        </Box>
    )
}
