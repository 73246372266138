import { Box, Card, CardActionArea, CardContent, Grid, Paper, useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import React from "react";
import ReactCountryFlag from "react-country-flag";
import { GetCodeByName } from "../DB/countries";


function ResponsiveMatchComponent() {

    const theme = useTheme();
    const isMd = useMediaQuery(theme.breakpoints.up('md'));


    return (
        <Paper sx={{maxWidth: 120}}>
            <Card>
                <CardActionArea>
                    <CardContent>

                        {isMd ?

                            <Grid>

                            </Grid>
                            :
                            <Grid container columns={16} spacing={1} sx={{justifyContent: "center"}}>
                                <Grid item>
                                    <ReactCountryFlag countryCode={GetCodeByName("Spain")} svg style={{
                                        fontSize: '2em',
                                        lineHeight: '2em',
                                    }} />
                                </Grid>
                                <Grid item>
                                    <ReactCountryFlag countryCode={GetCodeByName("Spain")} svg style={{
                                        fontSize: '2em',
                                        lineHeight: '2em',
                                    }} />
                                </Grid>
                            </Grid>
                        }

                    </CardContent>
                </CardActionArea>
            </Card>
        </Paper>
    )
}

export default ResponsiveMatchComponent;


